import { Link } from 'gatsby'
import * as React from 'react'
import Layout from "../components/layout"

const Portfolio = () => (
    <Layout>
        <h1>Portfolio</h1>
    </Layout>
)

export default Portfolio